import { Routes } from '@angular/router';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'design-system',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadComponent: () => import('./start/start.component')
  },
  {
    path: 'app',
    loadComponent: () => import('./layout/layout.component'),
    children: [
      {
        path: 'mit-hold',
        loadComponent: () => import('./my-team/my-team.component')
      },
      {
        path: 'ligaer',
        loadComponent: () => import('./leagues/leagues.component')
      },
      {
        path: 'analyse',
        loadComponent: () => import('./analysis/analysis.component')
      },
      {
        path: 'profil',
        loadComponent: () => import('./profile/profile.component')
      }
    ]
  },

  // DESIGN SYSTEM
  {
    path: 'design-system',
    loadComponent: () => import('./design-system/design-system.component')
  }
];
